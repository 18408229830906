<template>
    <CRow>
    <CCol col>
      <CCard>
        <CCardHeader>
          <CIcon name="cil-justify-center"/>
          <strong> Eventos</strong>
          <div class="card-header-actions">
              <CButton color="primary" @click="primaryModal = true" class="mr-1">
                Agregar Evento
              </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          
          <CDataTable
            :items="items"
            :fields="fields"
            column-filter
            table-filter
            items-per-page-select
            :items-per-page="10"
            hover
            sorter
            pagination
          >
            <template #status="{item}">
              <td>
                <CBadge :color="getBadge(item.status)">
                  {{item.status}}
                </CBadge>
              </td>
            </template>

            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  @click="toggleDetails(item, index)"
                >
                  {{Boolean(item._toggled) ? 'Ocultar' : 'Ver'}}
                </CButton>
              </td>
            </template>

            <template #details="{item}">
              <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                <CCardBody>
                  <CMedia :aside-image-props="{ height: 102 }">
                    <h4>
                      Nombre: {{item.username}}
                    </h4>
                    <p class="text-muted">Registrado: {{item.registered}}</p>
                    <CButton size="sm" color="info" class="">
                      Editar
                    </CButton>
                    <CButton size="sm" color="danger" class="ml-1">
                      Eliminar
                    </CButton>
                  </CMedia>
                </CCardBody>
              </CCollapse>
            </template>

          </CDataTable>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>
const items = [
  { username: 'Samppa Nori', registered: '2012/01/01', role: 'Member', status: 'Active'},
  { username: 'Estavan Lykos', registered: '2012/02/01', role: 'Staff', status: 'Banned'},
  { username: 'Chetan Mohamed', registered: '2012/02/01', role: 'Admin', status: 'Inactive'}
]

//console.log(items);

const fields = [
  { key: 'username', label: 'Acciones', _style:'min-width:200px' },
  { key: 'registered', label: 'Fecha registro' },
  { key: 'role', label: 'Rol', _style:'min-width:100px;' },
  { key: 'status', label: 'Estado', _style:'min-width:100px;' },
  { key: 'show_details', label: 'Acciones', _style: 'width:1%', sorter: false, filter: false }
]

export default {
  name: 'AdvancedTables',
  data () {
    return {
      items: items.map((item, id) => { return {...item, id}}),
      fields,
      details: [],
      collapseDuration: 0
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    }
  }
};
console.log('Primero');
//Le agrega un campo id al array
console.log(items.map((item, id) => { return {...item, id}}));
console.log('Segundo');
console.log(items);
console.log('Primero');
//Le agrega un campo id al array
console.log(items.map((item, inde) => { return {...item, inde}}));
</script>

<style>
</style>