var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":""}},[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-justify-center"}}),_c('strong',[_vm._v(" Eventos")]),_c('div',{staticClass:"card-header-actions"},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"primary"},on:{"click":function($event){_vm.primaryModal = true}}},[_vm._v(" Agregar Evento ")])],1)],1),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"column-filter":"","table-filter":"","items-per-page-select":"","items-per-page":10,"hover":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_vm._v(" "+_vm._s(Boolean(item._toggled) ? 'Ocultar' : 'Ver')+" ")])],1)]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('CMedia',{attrs:{"aside-image-props":{ height: 102 }}},[_c('h4',[_vm._v(" Nombre: "+_vm._s(item.username)+" ")]),_c('p',{staticClass:"text-muted"},[_vm._v("Registrado: "+_vm._s(item.registered))]),_c('CButton',{attrs:{"size":"sm","color":"info"}},[_vm._v(" Editar ")]),_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"danger"}},[_vm._v(" Eliminar ")])],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }